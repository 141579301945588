import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { API_GET_LOGS, token } from "constants/constants";
import { createDateLookup } from "constants/usefulFunctions";

function Logs({ user_id }) {
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: false,
    exportAllData: true,
    selection: false,
    editable: false,
  };

  useEffect(() => {
    async function fetchData() {
      await axios.post(API_GET_LOGS, { user_id }, token).then((res) => {
        let data = res.data.data;
        setState({
          columns: [
            { title: "Type", field: "type" },
            { title: "Operator", field: "operator", hidden: true },
            { title: "IP", field: "ip_address" },
            { title: "Device", field: "device" },
            { title: "Device Type", field: "device_type" },
            { title: "Platform", field: "platform" },
            {
              title: "Platform Version",
              field: "platform_version",
              hidden: true,
            },
            { title: "Browser", field: "browser" },
            {
              title: "Browser Version",
              field: "browser_version",
              hidden: true,
            },
            createDateLookup(),
          ],
          data,
        });
      });
      setLoading(false);
    }

    fetchData();
  }, []);
  return (
    <div className="card">
      <MaterialTable
        options={options}
        isLoading={loading}
        columns={state.columns}
        data={state.data}
        title="Logs"
      />
    </div>
  );
}

export default Logs;
