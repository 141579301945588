import React, { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress } from "@mui/material";
import { token, API_GET_SEARCH } from "constants/constants";
import { withRouter } from "react-router-dom";
import debounce from "lodash/debounce";
import "./searchbox.scss";

function SearchBox({ styleName, history }) {
  const [options, setOptions] = useState([]);

  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (inputValue.length > 3) request();
  }, [inputValue]);

  const request = () => {
    if (!loading) setLoading(true);
    axios
      .post(API_GET_SEARCH, { keyword: String(inputValue) }, token)
      .then((res) => {
        setLoading(false);
        setOptions(res.data.data);
      });
  };

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      option.full_name +
      option.email +
      option.mobile?.toString() +
      option.iban?.toString() +
      option.website_domain +
      option?.channel_name,
  });

  const handleChange = debounce((newValue) => {
    setInputValue(newValue);
  }, 400);

  return (
    <div
      className={`searchbox search-bar right-side-icon bg-transparent ${styleName}`}
      style={{ width: "320px", maxWidth: "420px" }}
    >
      <Autocomplete
        id="combo-box-demo"
        fullWidth
        options={options}
        getOptionLabel={(option) => option.full_name ?? ""}
        loading={loading}
        filterOptions={filterOptions}
        renderOption={(e, option) => {
          return (
            <div
              onClick={(v) => {
                e.onClick(v);
                history.push(
                  "/app/users/" + String(option.id)
                );
              }}
              className={`${e.className} option-container`}
            >
              <div>
                <span>Name: </span>
                {String(option.full_name)}
              </div>
            </div>
          );
        }}
        onInputChange={(_, newValue) => {
          handleChange(newValue);
        }}
        onChange={(_, newValue) => {
          setValue(newValue);
          setLoading(false);
        }}
        value={value}
        disablePortal
        clearOnEscape
        autoHighlight
        blurOnSelect
        selectOnFocus
        handleHomeEndKeys
        onBlur={() => {
          setInputValue("");
          setLoading(false);
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Search"
              variant="outlined"
              style={{ backgroundColor: "#eafa" }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <div
                    style={{
                      backgroundColor: "white",
                      height: 36,
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {loading && <CircularProgress color="primary" size={30} />}

                    {params.InputProps.endAdornment}
                  </div>
                ),
              }}
            />
          );
        }}
      />
    </div>
  );
}

export default withRouter(SearchBox);
