import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

const AppPieChart = ({ strength, data, height = 150 }) => {
  const COLORS = ["#792987", "#d9d9d9", "#FA8C16", "#f5222d"];

  return (
    <ResponsiveContainer width="100%" height={height}>
      <PieChart>
        <text
          x="50%"
          className="h1"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {strength}%
        </text>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={47}
          outerRadius={57}
          fill="#8884d8"
        >
          {data.map((_, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default AppPieChart;
