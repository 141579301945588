import {
  LOGIN_ADMIN_DEFAULT,
  LOGIN_ADMIN_FAILURE,
  LOGIN_ADMIN_STARTED,
  LOGIN_ADMIN_SUCCESS,
  LOGOUT_ADMIN_SUCCESS,
  VERIFY_ADMIN_FAILURE,
  VERIFY_ADMIN_STARTED,
  VERIFY_ADMIN_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: LOGIN_ADMIN_DEFAULT,
  authToken: "",
  adminId: "",
  adminRoleId: "",
  fullName: "",
  department: "",
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ADMIN_STARTED:
    case VERIFY_ADMIN_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case LOGIN_ADMIN_SUCCESS:
    case VERIFY_ADMIN_SUCCESS:
    case LOGOUT_ADMIN_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case LOGIN_ADMIN_FAILURE:
    case VERIFY_ADMIN_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
