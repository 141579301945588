import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {
  Flags,
  Currencies,
  API_GET_USER_FINANCE_TOTAL,
  token,
} from "constants/constants";
import StatusLoading from "../StatusLoading";
import Axios from "axios";

const UserTotalReport = ({ user_id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    paging: false,
    pageSize: 9,
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    Axios.post(API_GET_USER_FINANCE_TOTAL, { user_id }, token)
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error, "Error");
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Currency",
      field: "id",
      render: (rowData) => (
        <>
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              <i className={`flag flag-24 flag-${Flags[rowData.id]}`} />
            </div>
            <div
              className="jr-profile-banner-avatar-info"
              style={{ marginTop: -4, marginLeft: -10 }}
            >
              {Currencies[rowData.id]}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Top Up",
      field: "topup",
    },
    {
      title: "Website Gateway",
      field: "website_gateway",
    },
    {
      title: "Yekpay Link",
      field: "yekpay_link",
    },
    {
      title: "Personal Gateway",
      field: "personal_gateway",
    },
    {
      title: "Payout",
      field: "payout",
    },
    {
      title: "Refund",
      field: "refund",
    },
    {
      title: "Plugin",
      field: "plugin",
    },
    {
      title: "Subscription",
      field: "subscription",
    },
    {
      title: "Commission",
      field: "commission",
    },
  ];

  if (loading)
    return (
      <div className="my-5">
        <StatusLoading />;
      </div>
    );
  return (
    <MaterialTable
      isLoading={loading}
      options={options}
      columns={columns}
      data={data}
      title="Total"
    />
  );
};

export default UserTotalReport;
