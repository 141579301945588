import React from "react";
import IntlMessages from "util/IntlMessages";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import { Badge } from "reactstrap";

export const NavHeader = ({ title = "" }) => (
  <li className="nav-header">
    <IntlMessages id={title} />
  </li>
);

export const NavSubMenu = ({ item }) => (
  <ul className="sub-menu" id={item.url}>
    <li>
      <NavLink className="prepend-icon" to={item?.url} onClick={item?.onClick}>
        <span className="nav-text">
          <IntlMessages id={item.title} />
        </span>
        {item?.badge && (
          <Badge color="secondary" className="badge-pill ">
            {item.badge}
          </Badge>
        )}
      </NavLink>
    </li>
  </ul>
);

export const NavWithSubmenu = ({
  icon = "",
  title = "",
  subMenuList = [{ title: "", url: "", hideMenu: false, badge: null }],
}) => {
  const openCollapse = () => {
    const activeList = document.querySelectorAll(".no-arrow.open");
    if (activeList?.length) activeList[0]?.classList.remove("open");
    const current = document.getElementById(title);
    if (current.classList.contains("open")) current?.classList.remove("open");
    else current?.classList.add("open");
  };
  return (
    <li className="menu collapse-box" id={title} onClick={openCollapse}>
      <Button>
        <i className={icon} />
        <span className="nav-text">
          <IntlMessages id={title} />
        </span>
      </Button>

      {subMenuList.map((item) => {
        if (item.hideMenu) return null;
        return <NavSubMenu key={item?.title} item={item} />;
      })}
    </li>
  );
};

export const NavMenu = ({ url = "", title = "", icon = "", badge = "" }) => {
  const activeMenu = () => {
    const activeList = document.querySelectorAll(".no-arrow.open");
    if (activeList?.length) activeList[0].classList.remove("open");
    const current = document.getElementById(url);
    current.classList.add("open");

    const activeCollapse = document.querySelectorAll(".collapse-box.open");
    if (activeCollapse?.length) activeCollapse[0]?.classList.remove("open");
  };
  return (
    <li className={"menu no-arrow"} id={url}>
      <NavLink className="prepend-icon" to={url} onClick={activeMenu}>
        <i className={icon} />
        <span className="nav-text">
          <IntlMessages id={title} />
        </span>
        {badge && (
          <Badge color="secondary" className="badge-pill">
            {badge}
          </Badge>
        )}
      </NavLink>
    </li>
  );
};
