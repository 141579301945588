import { put, takeLatest } from "redux-saga/effects";
import {
  loginAdminFailure,
  loginAdminStarted,
  loginAdminSuccess,
  logoutAdminFailure,
  logoutAdminStarted,
  logoutAdminSuccess,
  verifyAdminFailure,
  verifyAdminStarted,
  verifyAdminSuccess,
} from "redux/actions/Auth";
import { history } from "redux/store";
import {
  LOGIN_ADMIN_REQUEST,
  LOGOUT_ADMIN_REQUEST,
  VERIFY_ADMIN_REQUEST,
} from "redux/types";
import {
  loginAdminAPI,
  logoutAdminAPI,
  verifyAdminAPI,
} from "services/API/Auth/index";
import { NotificationManager } from "react-notifications";
// --------------------------------------------------------------------------------
function* onLoginAdmin({ payload }) {
  yield put(loginAdminStarted());
  try {
    let response = yield loginAdminAPI(payload);
    if (!response.error) {
      yield put(loginAdminSuccess());
      NotificationManager.success(response.message);
    } else {
      yield put(loginAdminFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(loginAdminFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onVerifyAdmin({ payload }) {
  yield put(verifyAdminStarted());
  try {
    let response = yield verifyAdminAPI(payload);
    if (!response.error) {
      let { token, id, first_name, last_name } = response.data;
      yield put(
        verifyAdminSuccess({
          authToken: token,
          adminId: id,
          fullName: `${first_name} ${last_name}`,
        })
      );
      history.push("/app/dashboard");
    } else {
      yield put(verifyAdminFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(verifyAdminFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onLogoutAdmin({ payload }) {
  yield put(logoutAdminStarted());
  try {
    let response = yield logoutAdminAPI(payload);
    if (!response.error) {
      yield put(logoutAdminSuccess({ authToken: "" }));
    } else {
      yield put(logoutAdminFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(logoutAdminFailure({ error: error.message }));
  }
}
// ================================================================================
function* authSaga() {
  yield takeLatest(LOGIN_ADMIN_REQUEST, onLoginAdmin);
  yield takeLatest(VERIFY_ADMIN_REQUEST, onVerifyAdmin);
  yield takeLatest(LOGOUT_ADMIN_REQUEST, onLogoutAdmin);
}

export default authSaga;
