import React from "react";
import { NavHeader, NavMenu } from "containers/SideNav/components";

const Role = () => {
  return (
    <>
      <NavHeader title="sidebar.role" permission="Role" />
      <NavMenu
        title="sidebar.add.role"
        icon="zmdi zmdi-account-box zmdi-hc-fw"
        url="/app/role/add"
        permission="Create Role"
      />
      <NavMenu
        title="sidebar.role.list"
        icon="zmdi zmdi-accounts-list zmdi-hc-fw"
        url="/app/role/list"
        permission="All Role"
      />
    </>
  );
};

export default Role;
