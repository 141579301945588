import React from "react";
import { NavHeader, NavMenu } from "containers/SideNav/components";

const Income = () => (
  <>
    <NavHeader title="sidebar.income" permission="Income" />

    <NavMenu
      title="sidebar.transfer.fee"
      icon="zmdi zmdi-money-box"
      url="/app/income/transfer-fee"
      permission="Transfer Income"
    />
    <NavMenu
      title="sidebar.exchange.fee"
      icon="zmdi zmdi-money-box"
      url="/app/income/exchange-fee"
      permission="Exchange Income"
    />
    <NavMenu
      title="sidebar.payout.fee"
      icon="zmdi zmdi-money-box"
      url="/app/income/payout-fee"
      permission="Payout Income"
    />
  </>
);

export default Income;
