import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Transactions = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        exact
        path={`${match.url}/pending`}
        component={asyncComponent(() => import("./routes/WaitingForApprove"))}
      />
      <Route
        exact
        path={`${match.url}/pending/status-determination`}
        component={asyncComponent(() =>
          import("./routes/WaitingForApprove/StatusDetermination")
        )}
      />
      <Route
        path={`${match.url}/approved`}
        component={asyncComponent(() =>
          import("./routes/ApprovedTransactions")
        )}
      />
      <Route
        path={`${match.url}/declined`}
        component={asyncComponent(() =>
          import("./routes/DeclinedTransactions")
        )}
      />
      <Route
        path={`${match.url}/refunded`}
        component={asyncComponent(() =>
          import("./routes/RefundedTransactions")
        )}
      />
      <Route
        path={`${match.url}/executive`}
        component={asyncComponent(() =>
          import("./routes/ExecutiveTransactions")
        )}
      />
      <Route
        path={`${match.url}/all`}
        component={asyncComponent(() => import("./routes/AllTransactions"))}
      />
      <Route
        path={`${match.url}/exchanges-list`}
        component={asyncComponent(() => import("./routes/ExchangesList"))}
      />
      <Route
        path={`${match.url}/transfers-list`}
        component={asyncComponent(() => import("./routes/TransfersList"))}
      />
    </Switch>
  </div>
);

export default Transactions;
