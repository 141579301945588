import React from 'react';
import moment from 'moment-jalaali';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';

const DateTooltip = ({ data, restoreFunc, rowData }) => {
  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid purple',
      fontSize: '14px',
      padding: '5px'
    }
  }))(Tooltip);
  if (!data) return <></>;
  return (
    <CustomTooltip title={data}>
      <div style={{ cursor: 'pointer' }}>
        {moment(data)?.format('jYYYY-jMM-jDD HH:mm:ss')}
        {restoreFunc && (
          <IconButton onClick={() => restoreFunc(rowData.id, rowData.user_id)} className="icon-btn">
            <i style={{ color: 'primary' }} className="zmdi zmdi-redo zmdi-hc-fw text-warning" />
          </IconButton>
        )}
      </div>
    </CustomTooltip>
  );
};

export default DateTooltip;
