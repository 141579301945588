import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer from "./Auth";
import settings from "./Settings";
import MessageDialog from './Dialog';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    settings,
    MessageDialog, 
  });
