import appLocaleData from 'react-intl/locale-data/fa';
import enMessages from '../locales/fa_IR.json';

const FaLang = {
  messages: {
    ...enMessages
  },
  locale: 'fa-IR',
  data: appLocaleData
};
export default FaLang;
