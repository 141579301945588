import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import {
  API_GET_TRANSACTION,
  API_GET_TRANSACTION_refund,
  Currencies,
  Events,
  fromPrevMonth,
  Third_Status,
  to,
  token
} from 'constants/constants';
import {
  createDateLookup,
  eventCode,
  getIdentityStatus,
  thousands_separators
} from 'constants/usefulFunctions';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import YPDatePicker from 'components/datePicker';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { NotificationContainer } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import NumberFormat from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';

function Transactions({ user_id }) {
  const [date, setDate] = useState({
    from: fromPrevMonth,
    to,
    isGregorian: false
  });
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [state, setState] = useState({});
  const [refund, setRefund] = useState({
    open: false,
    currency_id: null,
    id: null
  });
  const [values, setValues] = useState({
    description: null,
    amount: null,
    success: false,
    error: false
  });

  const options = {
    columnsButton: true,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    exportName: 'Transactions',
    pageSize: 20
  };

  const handleClickOpen = (currency_id, id) => {
    setRefund({ open: true, currency_id: currency_id, id: id });
  };

  const handleRequestClose = () => {
    setRefund({ ...refund, open: false });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  const handleRequestDone = () => {
    if (values.description !== null) {
      setLoadingRefund(true);
      axios
        .post(
          API_GET_TRANSACTION_refund,
          {
            amount: values.amount,
            description: values.description,
            transaction_id: refund.id,
            user_id: user_id
          },
          token
        )
        .then((response) => {
          if (response.status === 200 && response.data.status === 'success') {
            setValues({ ...values, success: true });
          } else {
            setValues({ ...values, error: true });
          }
          setLoadingRefund(false);
        })
        .catch((error) => {
          let failedValue = '';
          if (typeof error.response.data.data === 'string') {
            failedValue = error.response.data.data;
          } else {
            for (var prop in error.response.data.data) {
              failedValue += prop + ': ' + error.response.data.data[prop] + ' - ';
            }
          }
          setValues({ ...values, error: true, code: failedValue });
          setLoadingRefund(false);
        });
      setRefund({ open: false });
    }
  };

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      await axios
        .post(API_GET_TRANSACTION, { user_id, from: date.from, to: date.to }, token)
        .then((res) => {
          let data = res.data.data.transactions;
          console.log(data, 'test');
          setState({
            columns: [
              {
                title: 'Code',
                field: 'id',
                render: (rowData) => eventCode(rowData)
              },
              {
                title: 'Event',
                field: 'event_id',
                render: (rowData) => Events[rowData.event_id],
                searchable: true,
                lookup: Events
              },
              {
                title: 'Amount',
                field: 'amount',
                render: (rowData) =>
                  rowData.amount
                    ? thousands_separators(
                        rowData.currency_id === 3 ? Math.trunc(rowData.amount) : rowData.amount
                      )
                    : 0
              },
              {
                title: 'Currency',
                field: 'currency_id',
                render: (rowData) => Currencies[rowData.currency_id],
                lookup: Currencies
              },
              {
                title: 'Status',
                field: 'status',
                render: (rowData) => getIdentityStatus(rowData.status),
                lookup: Third_Status
              },
              { title: 'Payment Gateway', field: 'payment_gateway' },
              { title: 'Tracking Code', field: 'tracking_code' },
              { title: 'Description', field: 'description' },
              { title: 'source', field: 'source' },
              { title: 'Type', field: 'type' },
              { title: 'VAT', field: 'vat' },
              {
                title: 'Refund',
                field: 'refund',
                render: (rowData) =>
                  (rowData.currency_id != 3 && rowData.event_id === 1) ||
                  rowData.event_id === 3 ||
                  rowData.event_id === 9 ||
                  rowData.event_id === 12 ? (
                    <>
                      <IconButton
                        onClick={() => handleClickOpen(rowData.currency_id, rowData.id)}
                        className="icon-btn">
                        <i
                          style={{ color: 'primary' }}
                          className="zmdi zmdi-redo zmdi-hc-fw text-warning"
                        />
                      </IconButton>{' '}
                    </>
                  ) : null,
                initialEditValue: '-'
              },
              createDateLookup()
            ],
            data: data
          });
        });
      setLoading(false);
      setSubmit(false);
    }

    fetchData();
  }, [submit, date.isGregorian]);

  return (
    <>
      <div>
        <Dialog open={refund.open} onClose={handleRequestClose}>
          <DialogTitle>Refund</DialogTitle>
          <DialogContent>
            <DialogContentText>Amount</DialogContentText>
            <div
              style={{ border: '1px solid #0000003b', borderRadius: 4 }}
              className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
              inputMode="numeric">
              <label
                style={{ backgroundColor: '#fff', padding: '0px 6px' }}
                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined"
                data-shrink="true">
                Amount
              </label>
              <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-adornedStart MuiOutlinedInput-adornedStart">
                <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                  <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary">
                    {Currencies[refund.currency_id]}
                  </p>
                </div>
                <NumberFormat
                  className="MuiInputBase-input MuiOutlinedInput-input"
                  value={values.amount}
                  onChange={handleChange('amount')}
                  thousandSeparator={true}
                />
              </div>
            </div>
          </DialogContent>
          <DialogContent>
            <DialogContentText>Description</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              fullWidth
              required={true}
              value={values.description}
              onChange={handleChange('description')}
              multiline
              className="jr-wall-textarea"
              variant="outlined"
              rows="3"
              inputProps={{ dir: 'auto' }}
            />
          </DialogContent>
          <DialogActions className="pr-4">
            <Button
              onClick={handleRequestClose}
              variant="contained"
              className="bg-danger text-white">
              Cancel
            </Button>
            <Button
              variant="contained"
              className="bg-success text-white"
              onClick={handleRequestDone}>
              {loadingRefund ? <CircularProgress color="inherit" size={14} /> : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <div className="jr-card">
            <YPDatePicker
              date={date}
              onHandleDate={() => setSubmit(true)}
              onChangeFrom={(from) => setDate({ ...date, from })}
              onChangeTo={(to) => setDate({ ...date, to })}
              onChangeSwitch={() => setDate({ ...date, isGregorian: !date.isGregorian })}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontSize: 18 }} className="font-weight-bold">
              Transactions
            </Typography>
          </AccordionSummary>

          <MaterialTable
            options={options}
            isLoading={loading}
            columns={state.columns}
            data={state.data}
            title={false}
          />
        </Accordion>
      </div>
      <div className="jr-main-content mt-4">
        <NotificationContainer />
        <SweetAlert
          show={values.success}
          success
          title={'Success'}
          confirmBtnText={'Close'}
          onConfirm={onConfirm}></SweetAlert>
        <SweetAlert
          show={values.error}
          error
          title={'Error'}
          confirmBtnText={'Close'}
          onConfirm={onConfirm}>
          Error! {values.code ? values.code : ''}
        </SweetAlert>
      </div>
    </>
  );
}

export default Transactions;
