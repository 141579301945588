import {
  HIDE_MESSAGE_DIALOG,
  SHOW_MESSAGE_DIALOG,
} from "constants/ActionTypes";

export const showMessageDialog = ({ title, description, status }) => {
  return {
    type: SHOW_MESSAGE_DIALOG,
    payload: {
      title,
      description,
      status,
    },
  };
};

export const hideMessageDialog = () => {
  return {
    type: HIDE_MESSAGE_DIALOG,
  };
};
