import React, { useState } from "react";
import {
  ApiURL,
  API_CHANGE_STATUS,
  API_USER_EDIT,
  token,
} from "constants/constants";
import Button from "@mui/material/Button";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IdCard from "assets/images/default/id-card.png";
import { Done, Clear } from "@mui/icons-material";

import { NotificationContainer } from "react-notifications";
import AppSwitch from "components/AppSwitch";
import AppDropDown from "components/AppDropDown";
import AppPieChart from "components/AppPieChart";
import AppTextInput from "components/AppTextInput";
import AppButton from "components/AppButton";
import MainModal from "components/MainModal";

const UserInfo = (user) => {
  const basicInfo = user.basicInfo;

  const [modal, setModal] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const toggle = () => setModal(!modal);
  const [values, setValues] = useState({
    first_name: basicInfo.first_name,
    last_name: basicInfo.last_name,
    username: basicInfo.username,
    mobile: basicInfo.mobile,
    national_code: basicInfo.national_code,
    phone: basicInfo.phone,
    parent_id: basicInfo.parent_id,
    identity_status: basicInfo.identity_status,
    identity_comment: basicInfo.identity_comment,
    otp: basicInfo.otp === "Active",
    renew: basicInfo.renew === "Active",
    sms_notification: basicInfo.sms_notification === "Active",
    email_notification: basicInfo.email_notification === "Active",
    success: false,
    error: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChange2 = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  function handleSave() {
    setLoadingStatus(true);
    axios
      .post(
        API_CHANGE_STATUS,
        {
          status_field: "identity_status",
          value: values.identity_status,
          comment: values.identity_comment,
          user_id: basicInfo.id,
        },
        token
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          setValues({ ...values, success: true });
        } else {
          setValues({ ...values, error: true });
        }
        setLoadingStatus(false);
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        setValues({ ...values, error: true, code: failedValue });
        setLoadingStatus(false);
      });
  }

  const data = [
    { value: basicInfo.strength },
    { value: 100 - basicInfo.strength },
  ];

  function handleSaveUser() {
    setLoadingUser(true);
    axios
      .post(
        API_USER_EDIT,
        {
          first_name: values.first_name,
          last_name: values.last_name,
          username: values.username,
          mobile: values.mobile,
          phone: values.phone,
          parent_id: values.parent_id,
          national_code: values.national_code,
          otp: values.otp,
          renew: values.renew,
          sms_notification: values.sms_notification,
          email_notification: values.email_notification,
          user_id: basicInfo.id,
        },
        token
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          // setValues({...values, success: true});
          let data = response.data.data;
          setValues({
            first_name: data.first_name,
            last_name: data.last_name,
            username: data.username,
            mobile: data.mobile,
            national_code: data.national_code,
            phone: data.phone,
            parent_id: data.parent_id,
            identity_status: data.identity_status,
            identity_comment: data.identity_comment,
            otp: data.otp,
            renew: data.renew,
            sms_notification: data.sms_notification,
            email_notification: data.email_notification,
            success: true,
            error: false,
          });
        } else {
          setValues({ ...values, error: true });
        }
        setLoadingUser(false);
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }

        setLoadingUser(false);
      });
  }

  function handleRestoreAccount() {}

  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  return (
    <div className="row">
      <NotificationContainer />
      <SweetAlert
        show={values.success}
        success
        title={"Success"}
        confirmBtnText={"Close"}
        onConfirm={onConfirm}
      >
        Successfully Changed!
      </SweetAlert>
      <SweetAlert
        show={values.error}
        error
        title={"Error"}
        confirmBtnText={"Close"}
        onConfirm={onConfirm}
      >
        Error! {values.code ? values.code : ""}
      </SweetAlert>

      <div className="col-lg-6">
        <AppTextInput
          label="First Name"
          value={values.first_name}
          onChange={handleChange("first_name")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label="last Name"
          value={values.last_name}
          onChange={handleChange("last_name")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label="User Name"
          value={values.username}
          onChange={handleChange("username")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label={"Email "}
          value={basicInfo.email}
          onChange={handleChange}
          dir="ltr"
          InputProps={{
            endAdornment:
              basicInfo.email_status === "Active" ? (
                <Done className="text-green" />
              ) : (
                <Clear className="text-red" />
              ),
          }}
          disabled={basicInfo.deleted_at != null ? true : false}
        />

        <AppTextInput
          label="Mobile"
          value={values.mobile}
          onChange={handleChange("mobile")}
          shrink="false"
          dir="ltr"
          InputProps={{
            endAdornment:
              basicInfo.mobile_status === "Active" ? (
                <Done className="text-green" />
              ) : (
                <Clear className="text-red" />
              ),
          }}
          disabled={basicInfo.deleted_at != null ? true : false}
        />

        <AppTextInput
          label="National Code"
          value={values.national_code}
          onChange={handleChange("national_code")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label="Phone"
          value={values.phone}
          onChange={handleChange("phone")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label="Parent Id"
          value={values.parent_id}
          onChange={handleChange("parent_id")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label="Created at"
          value={basicInfo.created_at}
          onChange={handleChange}
          disabled
          dir="ltr"
        />

        <AppTextInput
          label="Updated at"
          value={basicInfo.updated_at}
          onChange={handleChange}
          disabled
          dir="ltr"
        />

        <AppTextInput
          label="Deleted at"
          value={basicInfo.deleted_at}
          onChange={handleChange}
          disabled
          dir="ltr"
        />

        <div className="d-flex flex-wrap">
          <AppSwitch
            label="Auto Renew"
            checked={values.renew}
            onChange={handleChange2("renew")}
          />

          <AppSwitch
            label="OTP"
            checked={values.otp}
            onChange={handleChange2("otp")}
          />

          <AppSwitch
            label="SMS Notification"
            checked={values.sms_notification}
            onChange={handleChange2("sms_notification")}
          />

          <AppSwitch
            label="Email Notification"
            checked={values.email_notification}
            onChange={handleChange2("email_notification")}
          />
        </div>

        {basicInfo.deleted_at !== null ? (
          <>
            <span className="text-danger">
              Acount Deleted at {basicInfo.deleted_at}
            </span>
            <div className="d-flex flex-row align-items-center mt-2">
              <AppButton
                loading={loadingStatus}
                text={"Restore Account"}
                onClick={handleRestoreAccount}
              />
            </div>
          </>
        ) : (
          <div className="text-center">
            <AppButton
              loading={loadingStatus}
              text={"Save"}
              onClick={handleSaveUser}
              disabled={loadingUser}
              customClassName="mt-3"
            />
          </div>
        )}
      </div>

      <div className="col-lg-6 ">
        {basicInfo.identity_attachment &&
        basicInfo.identity_attachment.substring(
          basicInfo.identity_attachment.length - 4
        ) === ".pdf" ? (
          <iframe
            style={{ border: "1px solid #c4c4c4", borderRadius: "4px" }}
            src={ApiURL + basicInfo.identity_attachment}
            width="100%"
            height="400px"
          ></iframe>
        ) : (
          <div
            onClick={toggle}
            style={{
              width: "100%",
              height: "400px",
              borderRadius: "4px",
              backgroundImage: `url(${
                basicInfo.identity_attachment
                  ? ApiURL + basicInfo.identity_attachment
                  : IdCard
              })`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        )}

        <div className={`mt-3 d-flex flex-column align-items-center`}>
          <AppDropDown
            label="Status"
            value={values.identity_status}
            onChange={handleChange("identity_status")}
            items={[
              {
                label: "Approved",
                value: "Approved",
                className: "text-success",
              },
              { label: "Pending", value: "Pending", className: "text-warning" },
              {
                label: "Declined",
                value: "Declined",
                className: "text-danger",
              },
            ]}
          />

          <AppTextInput
            label="Comment"
            multiline
            onChange={handleChange("identity_comment")}
            value={values.identity_comment}
          />

          <AppButton
            loading={loadingStatus}
            text={"Save"}
            onClick={handleSave}
            disabled={loadingStatus}
            customClassName="mt-3"
          />
        </div>
        <div className="py-3">
          <AppPieChart strength={basicInfo?.strength} data={data} />
        </div>
      </div>

      <MainModal
        mainContent={
          <img
            alt="Passport"
            style={{ width: "100%" }}
            src={
              basicInfo.identity_attachment
                ? ApiURL + basicInfo.identity_attachment
                : IdCard
            }
          />
        }
        title="Passport"
        handleToggle={toggle}
        isOpen={modal}
        hasRotation
        footer={<AppButton color="primary" text="Close" onClick={toggle} />}
      />
    </div>
  );
};

export default UserInfo;
