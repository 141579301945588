import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";

const Loading = ({ componentMode }) => {
  return (
    <div
      className={classNames("loading-container", {
        "loading-in-component": componentMode,
      })}
    >
      <CircularProgress size={50} />
    </div>
  );
};

export default Loading;
