import axiosMiddleware from "../AxiosMiddleware";
// --------------------------------------------------------------------------------
export const loginAdminAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "login",
    body: data,
  });
export const verifyAdminAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "verify",
    body: data,
  });
export const logoutAdminAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "logout",
    body: data,
  });
