import React from "react";

const CardHeader = ({ title, status }) => {
  const getStatusColor = {
    Active: "green",
    Approved: "green",
    "Fraud Suspect": "orange",
    Blocked: "red",
  };

  return (
    <div className="app-card-header">
      {title?.toUpperCase()}{" "}
      {status && (
        <span className={`text-${getStatusColor[status]}`}>({status})</span>
      )}
    </div>
  );
};

export default CardHeader;
