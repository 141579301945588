import React, { Fragment } from "react";
import { NavHeader, NavMenu } from "containers/SideNav/components";
// --------------------------------------------------------------------------------
const Plan = () => {
  return (
    <Fragment>
      <NavHeader title="sidebar.plan" permission="Plan" />
      <NavMenu
        title="sidebar.plan.list"
        icon="zmdi zmdi-format-list-bulleted zmdi-hc-fw"
        url="/app/plan/plan-list"
        permission="All Plan"
      />
      <NavMenu
        title="sidebar.add.plan"
        icon="zmdi zmdi-plus zmdi-hc-fw"
        url="/app/plan/add-plan"
        permission="Create Plan"
      />
    </Fragment>
  );
};

export default Plan;
