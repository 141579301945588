import React, { useState, useEffect } from "react";
import ProfileHeader from "./ProfileHeader";
import Summary from "./Summary/index";
import Itsaaz from "./Itsaaz";
import UserLog from "./UserLog/index";
import Transactions from "./Transactions/index";
import UserStatus from "./UserStatus/index";
import BankAccounts from "./BankAccounts/index";
import Gateways from "./Gateways/index";
import YekpayLinks from "./YekpayLinks/index";
import Tickets from "./Tickets";
import UserBalance from "./UserBalance";
import Report from "./Report";
import TextMessage from "./TextMessage";
import UserPlans from "./UserPlans";
import AdminComments from "./AdminComments";
import Logs from "./Logs";
import Auxiliary from "util/Auxiliary";
import Company from "./Company";
import Billing from "./Billing";
import TransactionsFailed from "./TransactionsFailed";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import HelpIcon from "@mui/icons-material/Help";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import Room from "@mui/icons-material/Room";
import ClearAll from "@mui/icons-material/ClearAll";
import SwapHoriz from "@mui/icons-material/SwapHoriz";
import AccountBalance from "@mui/icons-material/AccountBalance";
import Launch from "@mui/icons-material/Launch";
import Message from "@mui/icons-material/Message";
import TrendingUp from "@mui/icons-material/TrendingUp";
import AssignmentLate from "@mui/icons-material/AssignmentLate";
import Feedback from "@mui/icons-material/Feedback";
import RequestPayout from "./RequestPayout";
import { useHistory, useParams } from "react-router-dom";
import AssessmentIcon from "@mui/icons-material/Assessment";
import APIs from "services/API/Main";
import useAPI from "services/hook";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";

const TabContainer = ({ children }) => {
  return <div className="pt-3">{children}</div>;
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const Profile = () => {
  const { id: user_id, section } = useParams();
  const history = useHistory();

  const currentPath = `/app/profile/user/${user_id}`;

  const [user, setUser] = useState({});
  const [verifyByItsaz, setVerifyByItsaz] = useState(false);

  useEffect(() => {
    if (user_id) fetchUserData({ user_id });
  }, [user_id]);

  const { request } = useAPI(APIs.getUserData);

  const fetchUserData = async () => {
    const data = await request({ user_id });
    document.title =
      "Profile | " + data?.basic.first_name + " " + data?.basic.last_name;
    setUser(data?.basic);
    setVerifyByItsaz(data?.itsaz);
  };

  const activeTab = {
    Summary: 0,
    Itsaaz: 1,
    Status: 2,
    Balance: 3,
    Report: 4,
    Company: 5,
    Billing: 6,
    Plans: 7,
    Transactions: 8,
    BankAccount: 9,
    Gateway: 10,
    YekpayLinks: 11,
    Tickets: 12,
    Logs: 13,
    RequestPayout: 14,
    TextMessage: 15,
    UserLog: 16,
  };

  return (
    <Auxiliary>
      <ProfileHeader basicInfo={user} />
      <div className="jr-profile-content">
        <div className="w-100">
          <AppBar position="static" color="inherit">
            <Tabs
              value={activeTab[section]}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              allowScrollButtonsMobile={true}
              textColor="primary"
            >
              <Tab
                label="Summary"
                icon={<PersonPinIcon />}
                onClick={() => history.push(`${currentPath}/Summary`)}
              />
              <Tab
                label="Itsaaz"
                icon={
                  <>
                    <PersonPinIcon
                      style={{
                        margin: "5px",
                      }}
                    />
                    {verifyByItsaz && (
                      <TaskAltRoundedIcon
                        style={{
                          position: "absolute",
                          right: "18px",
                          top: "10px",
                          color: "#5cb85c",
                        }}
                        fontSize="medium"
                      />
                    )}
                  </>
                }
                onClick={() => history.push(`${currentPath}/Itsaaz`)}
              />
              <Tab
                label="Status"
                icon={<HelpIcon />}
                onClick={() => history.push(`${currentPath}/Status`)}
              />
              <Tab
                label="Balance"
                icon={<AttachMoneyIcon />}
                onClick={() => history.push(`${currentPath}/Balance`)}
              />
              <Tab
                label="Report"
                icon={<AssessmentIcon />}
                onClick={() => history.push(`${currentPath}/Report`)}
              />
              <Tab
                label="Company"
                icon={<BusinessIcon />}
                onClick={() => history.push(`${currentPath}/Company`)}
              />
              <Tab
                label="Billing"
                icon={<Room />}
                onClick={() => history.push(`${currentPath}/Billing`)}
              />
              <Tab
                label="Plans"
                icon={<ClearAll />}
                onClick={() => history.push(`${currentPath}/Plans`)}
              />
              <Tab
                label="Transactions"
                icon={<SwapHoriz />}
                onClick={() => history.push(`${currentPath}/Transactions`)}
              />
              <Tab
                label="Bank Account"
                icon={<AccountBalance />}
                onClick={() => history.push(`${currentPath}/BankAccount`)}
              />
              <Tab
                label="Gateway"
                icon={<Launch />}
                onClick={() => history.push(`${currentPath}/Gateway`)}
              />
              <Tab
                label="Yekpay Links"
                icon={<AssignmentLate />}
                onClick={() => history.push(`${currentPath}/YekpayLinks`)}
              />
              <Tab
                label="Tickets"
                icon={<Message />}
                onClick={() =>
                  history.push({
                    pathname: `${currentPath}/Tickets`,
                    state: { email: user?.email },
                  })
                }
              />
              <Tab
                label="Logs"
                icon={<Feedback />}
                onClick={() => history.push(`${currentPath}/Logs`)}
              />
              <Tab
                label="Request Payout"
                icon={<TrendingUp />}
                onClick={() => history.push(`${currentPath}/RequestPayout`)}
              />
              <Tab
                label="Text Message"
                icon={<Message />}
                onClick={() =>
                  history.push({
                    pathname: `${currentPath}/TextMessage`,
                    state: { mobile: user?.mobile },
                  })
                }
              />
              <Tab
                label="User Log"
                icon={<PersonPinIcon />}
                onClick={() => history.push(`${currentPath}/UserLog`)}
              />
            </Tabs>
          </AppBar>
          {section === "Summary" && (
            <TabContainer>
              <Summary user={user} />
            </TabContainer>
          )}

          {section === "Itsaaz" && (
            <TabContainer>
              <Itsaaz user_id={user_id} />
            </TabContainer>
          )}
          {section === "Status" && (
            <TabContainer>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <UserStatus
                    refreshUserData={fetchUserData}
                    basicInfo={user}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <AdminComments user_id={user_id} basicInfo={user} />
                </div>
              </div>
            </TabContainer>
          )}
          {section === "Balance" && (
            <TabContainer>
              <UserBalance user_id={user_id} />
            </TabContainer>
          )}
          {section === "Report" && (
            <TabContainer>
              <Report user_id={user_id} />
            </TabContainer>
          )}
          {section === "Company" && (
            <TabContainer>
              <Company user_id={user_id} />
            </TabContainer>
          )}
          {section === "Billing" && (
            <TabContainer>
              <Billing user_id={user_id} />
            </TabContainer>
          )}
          {section === "Plans" && (
            <TabContainer>
              <UserPlans user_id={user_id} />
            </TabContainer>
          )}
          {section === "Transactions" && (
            <TabContainer>
              <Transactions user_id={user_id} />
              <TransactionsFailed user_id={user_id} />
            </TabContainer>
          )}
          {section === "BankAccount" && (
            <TabContainer>
              <BankAccounts user_id={user_id} />
            </TabContainer>
          )}
          {section === "Gateway" && (
            <TabContainer>
              <Gateways user_id={user_id} />
            </TabContainer>
          )}
          {section === "YekpayLinks" && (
            <TabContainer>
              <YekpayLinks user_id={user_id} />
            </TabContainer>
          )}
          {section === "Tickets" && (
            <TabContainer>
              <Tickets user_id={user_id} />
            </TabContainer>
          )}
          {section === "Logs" && (
            <TabContainer>
              <Logs user_id={user_id} />
            </TabContainer>
          )}
          {section === "RequestPayout" && (
            <TabContainer>
              <RequestPayout user_id={user_id} />
            </TabContainer>
          )}
          {section === "TextMessage" && (
            <TabContainer>
              <TextMessage user_id={user_id} />
            </TabContainer>
          )}
          {section === "UserLog" && (
            <TabContainer>
              <UserLog user_id={user_id} />
            </TabContainer>
          )}
        </div>
      </div>
    </Auxiliary>
  );
};

export default Profile;
