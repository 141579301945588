import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { ApiURL, Language, Currencies, First_Status2 } from 'constants/constants';
import {
  createDateLookup,
  getYekpayLinkStatus,
  thousands_separators
} from 'constants/usefulFunctions';
import DefaultAvatar from 'assets/images/default/company-logo.png';
import MainTable from 'components/MainTable';
import useAPI from 'services/hook';
import APIs from 'services/API/ProfileUser';

const YekpayLink = ({ user_id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const apiGetYekpayLink = useAPI(APIs.apiGetYekpayLink);
  const fetchData = async () => {
    const result = await apiGetYekpayLink.request({ user_id });
    if (result) {
      const formatedData = result.map((item) => {
        item.websiteLogo = item.image ? ApiURL + item.image : DefaultAvatar;
        return item;
      });
      setData(formatedData);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
      editable: 'never',
      render: (rowData) => (
        <>
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              <Avatar className="size-50 border" alt={rowData.name} src={rowData.websiteLogo} />
            </div>
            <div className="jr-profile-banner-avatar-info">
              <p className="mb-0 jr-fs-lg">
                <a target="_blank" href={rowData.link}>
                  {rowData.name}
                </a>
              </p>
            </div>
          </div>
        </>
      )
    },
    {
      title: 'Amount',
      field: 'amount',
      type: 'numeric',
      editable: 'never',
      render: (rowData) =>
        rowData.amount
          ? thousands_separators(
              rowData.currency_id === 3 ? Math.trunc(rowData.amount) : rowData.amount
            )
          : 0
    },
    {
      title: 'Currency',
      field: 'currency_id',
      editable: 'never',
      render: (rowData) => Currencies[rowData.currency_id]
    },
    { title: 'Description', field: 'description', editable: 'never' },
    createDateLookup(),
    { title: 'Deleted at', field: 'deleted_at', editable: 'never' },
    {
      title: 'Language',
      field: 'lang',
      editable: 'never',
      render: (rowData) => Language[rowData.lang]
    },
    {
      title: 'Status',
      field: 'status',
      lookup: First_Status2,
      render: (rowData) => getYekpayLinkStatus(rowData.status)
    }
  ];

  const options = {
    actionsColumnIndex: columns.length
  };

  const apiChangeYekpayLink = useAPI(APIs.apiChangeYekpayLink);
  const updateRow = async (id, status, updatedData) => {
    const result = await apiChangeYekpayLink.request({ user_id, id, status });
    if (result) setData([...updatedData]);
  };

  const apiDeleteYekpayLink = useAPI(APIs.apiDeleteYekpayLink);
  const deleteRow = async (user_id, id, updatedData) => {
    const result = await apiDeleteYekpayLink.request({ user_id, id });
    if (result) setData([...updatedData]);
  };

  return (
    <div className="card">
      <MainTable
        customOptions={options}
        isLocalization
        loading={loading}
        columns={columns}
        data={data}
        title="Yekpay Links"
        onRowDelete={(oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updatedData = data?.filter((item) => item.id !== oldData.id);
              deleteRow(oldData.user_id, oldData.id, updatedData);
              resolve();
            }, 600);
          })
        }
        onRowUpdate={(newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updatedData = [...data];
              updatedData[data.indexOf(oldData)] = newData;
              updateRow(oldData.id, newData.status, updatedData);
              resolve();
            }, 600);
          })
        }
      />
    </div>
  );
};

export default YekpayLink;
