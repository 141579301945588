import apiService from "services";
import {
  API_GET_AYANDE_BALANCE,
  API_GET_DASHBOARD,
  API_GET_ELASTIC_BALANCE,
  API_GET_KAVENEGAR_BALANCE,
  API_GET_PARSIAN_BALANCE,
  API_GET_USER,
  API_GET_USER_ITSAAZ,
} from "constants/constants";

const getDashbordInfo = () => apiService.post(API_GET_DASHBOARD);

const getElasticBalance = () => apiService.post(API_GET_ELASTIC_BALANCE);
const getAyandeBalance = () => apiService.post(API_GET_AYANDE_BALANCE);
const getKavenegarBalance = () => apiService.post(API_GET_KAVENEGAR_BALANCE);
const getParsianBalance = () => apiService.post(API_GET_PARSIAN_BALANCE);
const getUserData = (params) => apiService.post(API_GET_USER, params);
const userItsaaz = (params) => apiService.post(API_GET_USER_ITSAAZ, params);

export default {
  getDashbordInfo,
  getElasticBalance,
  getAyandeBalance,
  getKavenegarBalance,
  getParsianBalance,
  getUserData,
  userItsaaz,
};
