// ---------------------------------------- AUTH ----------------------------------------
export const LOGIN_ADMIN_DEFAULT = "LOGIN_ADMIN_DEFAULT";
export const LOGIN_ADMIN_REQUEST = "LOGIN_ADMIN_REQUEST";
export const LOGIN_ADMIN_STARTED = "LOGIN_ADMIN_STARTED";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILURE = "LOGIN_ADMIN_FAILURE";

export const VERIFY_ADMIN_REQUEST = "VERIFY_ADMIN_REQUEST";
export const VERIFY_ADMIN_STARTED = "VERIFY_ADMIN_STARTED";
export const VERIFY_ADMIN_SUCCESS = "VERIFY_ADMIN_SUCCESS";
export const VERIFY_ADMIN_FAILURE = "VERIFY_ADMIN_FAILURE";

export const LOGOUT_ADMIN_REQUEST = "LOGOUT_ADMIN_REQUEST";
export const LOGOUT_ADMIN_STARTED = "LOGOUT_ADMIN_STARTED";
export const LOGOUT_ADMIN_SUCCESS = "LOGOUT_ADMIN_SUCCESS";
export const LOGOUT_ADMIN_FAILURE = "LOGOUT_ADMIN_FAILURE";

// ---------------------------------------- MAIN ----------------------------------------
export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_STARTED = "GET_ALL_USERS_STARTED";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";
