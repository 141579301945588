import apiService from "services";
import {
  API_GET_COMMENTS,
  API_CREATE_COMMENTS,
  API_DELETE_COMMENTS,
  API_GET_BANK_ACCOUNT,
  API_CHANGE_STATUS_BANK_ACCOUNT,
  API_DELETE_BANK_ACCOUNT,
  API_CREATE_BANK_ACCOUNT,
  API_RESTORE_BANK_ACCOUNT,
  API_GET_BILLING,
  API_CHANGE_STATUS_BILLING,
  API_GET_GATEWAY,
  API_CHANGE_GATEWAY,
  API_GET_BALANCE,
  API_DELETE_BALANCE,
  API_CREATE_BALANCE,
  API_GET_COMPANY,
  API_CHANGE_STATUS_COMPANY,
  API_CREATE_PLANS,
  API_GET_PLANS,
  API_GET_YEKPAY_LINK,
  API_CHANGE_YEKPAY_LINK,
  API_DELETE_YEKPAY_LINK,
} from "constants/constants";

const apiGetComments = (params) => apiService.post(API_GET_COMMENTS, params);

const apiCreateComment = (params) =>
  apiService.post(API_CREATE_COMMENTS, params);

const apiDeleteComment = (params) =>
  apiService.post(API_DELETE_COMMENTS, params);

const apiGetBankAccount = (params) =>
  apiService.post(API_GET_BANK_ACCOUNT, params);

const apiChangeStatusBankAccount = (params) =>
  apiService.post(API_CHANGE_STATUS_BANK_ACCOUNT, params);

const apiDeleteBankAccount = (params) =>
  apiService.post(API_DELETE_BANK_ACCOUNT, params);

const apiCreatBankAccount = (params) =>
  apiService.post(API_CREATE_BANK_ACCOUNT, params);

const apiRestoreBankAccount = (params) =>
  apiService.post(API_RESTORE_BANK_ACCOUNT, params);

const apiGetBilling = (params) => apiService.post(API_GET_BILLING, params);

const apiChangeStatusBilling = (params) =>
  apiService.post(API_CHANGE_STATUS_BILLING, params);

const apiGetGateways = (params) => apiService.post(API_GET_GATEWAY, params);

const apiChangeGateway = (params) =>
  apiService.post(API_CHANGE_GATEWAY, params);

const apiGetBalance = (params) => apiService.post(API_GET_BALANCE, params);

const apiCreateBalance = (params) =>
  apiService.post(API_CREATE_BALANCE, params);

const apiDeleteBalance = (params) =>
  apiService.post(API_DELETE_BALANCE, params);

const apiGetCompany = (params) => apiService.post(API_GET_COMPANY, params);

const apiChangeStatusCompany = (params) =>
  apiService.post(API_CHANGE_STATUS_COMPANY, params);

const apiGetPlans = (params) => apiService.post(API_GET_PLANS, params);

const apiCreatePlans = (params) => apiService.post(API_CREATE_PLANS, params);

const apiGetYekpayLink = (params) =>
  apiService.post(API_GET_YEKPAY_LINK, params);

const apiChangeYekpayLink = (params) =>
  apiService.post(API_CHANGE_YEKPAY_LINK, params);

const apiDeleteYekpayLink = (params) =>
  apiService.post(API_DELETE_YEKPAY_LINK, params);

export default {
  apiCreateComment,
  apiDeleteComment,
  apiGetComments,
  apiGetBankAccount,
  apiChangeStatusBankAccount,
  apiDeleteBankAccount,
  apiCreatBankAccount,
  apiRestoreBankAccount,
  apiGetBilling,
  apiChangeStatusBilling,
  apiGetGateways,
  apiChangeGateway,
  apiGetBalance,
  apiDeleteBalance,
  apiCreateBalance,
  apiGetCompany,
  apiChangeStatusCompany,
  apiGetPlans,
  apiCreatePlans,
  apiGetYekpayLink,
  apiChangeYekpayLink,
  apiDeleteYekpayLink,
};
