import React from "react";
import { NavHeader, NavMenu } from "containers/SideNav/components";

const Announcments = () => {
  return (
    <>
      <NavHeader title="sidebar.announcements" permission="Announcement" />
      <NavMenu
        title="sidebar.announcements.list"
        icon="zmdi zmdi-wrench zmdi-hc-fw"
        url="/app/announcement/list"
        permission="All Announcement"
      />
      <NavMenu
        title="sidebar.add.announcement"
        icon="zmdi zmdi-wrench zmdi-hc-fw"
        url="/app/announcement/add"
        permission="Create Announcement"
      />
    </>
  );
};

export default Announcments;
