import React from "react";
import { NavHeader, NavMenu } from "containers/SideNav/components";

const UserOperations = () => (
  <>
    <NavHeader title="sidebar.useroperations" permission="Operation" />

    <NavMenu
      title="sidebar.topup"
      icon="zmdi zmdi-long-arrow-down"
      url="/app/user-operations/topup"
      permission="Create Topup Operation"
    />
    <NavMenu
      title="sidebar.topup.list"
      icon="zmdi zmdi-format-list-bulleted"
      url="/app/user-operations/topup-list"
      permission="All Topup Operation"
    />
    <NavMenu
      title="sidebar.rate"
      icon="zmdi zmdi-trending-up"
      url="/app/user-operations/rate"
      permission="All Topup Operation"
    />
    <NavMenu
      title="sidebar.rate.list"
      icon="zmdi zmdi-format-list-bulleted"
      url="/app/user-operations/rate-list"
      permission="All Topup Operation"
    />
    <NavMenu
      title="sidebar.payout"
      icon="zmdi zmdi-long-arrow-up"
      url="/app/user-operations/payout"
      permission="Payout Operation"
    />
    <NavMenu
      title="sidebar.exchange"
      icon="zmdi zmdi-swap"
      url="/app/user-operations/exchange"
      permission="Exchange Operation"
    />
    <NavMenu
      title="sidebar.internal.transfer"
      icon="zmdi zmdi-swap"
      url="/app/user-operations/internal-transfer"
      permission="Transfer Operation"
    />
  </>
);

export default UserOperations;
