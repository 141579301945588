import React from 'react';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';

const useAppSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Snackbar = ({ message, variant = 'success' }) =>
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 2000,
      action,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    });

  const action = (key) => (
    <>
      <CancelIcon
        style={{ cursor: 'pointer' }}
        onClick={() => {
          closeSnackbar(key);
        }}
      />
    </>
  );

  return { Snackbar };
};

export default useAppSnackbar;
