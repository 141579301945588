import React from "react";
import { withRouter } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import Main from "./List/Main/index";
import Transactions from "./List/Transactions";
import UserOperations from "./List/UserOperations";
import Income from "./List/Income/index";
import Plan from "./List/Plan";
import Announcement from "./List/Announcements";
import Role from "./List/Role";
import Admin from "./List/Admin";
import { history } from "redux/store";
import { useEffect } from "react";

const SidenavContent = () => {
  const pathname = `${history.location.pathname}`;
  useEffect(() => {
    const current = document.getElementById(pathname);
    current?.classList?.add("open");

    if (current?.classList?.contains("sub-menu"))
      current.parentElement.classList.add("open");
  }, [pathname]);

  return (
    <CustomScrollbars className=" scrollbar">
      <ul className="nav-menu">
        <Main />
        <Transactions />
        <UserOperations />
        <Income />
        <Plan />
        <Announcement />
        <Role />
        <Admin />
      </ul>
    </CustomScrollbars>
  );
};

export default withRouter(SidenavContent);
